import React from 'react'
import styled from '@emotion/styled'
import AnimatedIcon from 'src/components/AnimatedIcon'
import { colors, typography, animations, mq, util } from 'src/styles'

const Wrapper = styled.div`
	position: fixed;
  top: 0;
  left: 0;
  right: 0;
	width: 100%;
	height: 50px;
	// background: salmon;
	top: 0;
	z-index: 2;
`
const MenuIcon = styled.div`
	// display: none;
  padding: 5px 10px;
  margin-top: 10px;
  margin-left: 20px;
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  transition: color ${ animations.mediumSpeed } ease-in-out;
  span {
    display: block;
  }
`

const MobileHeader = ({ className, mobileMenuOpen, toggleMobileMenu }) => (
  <Wrapper className={className}>
  	<MenuIcon id="mobile-menu-icon" onClick={() => toggleMobileMenu(!mobileMenuOpen)}>
      <AnimatedIcon
        icon={mobileMenuOpen ? 'close' : 'menu'}
      />
    </MenuIcon>
  </Wrapper>
)

export default MobileHeader
