import React, { useState } from 'react'
import AppContext from './AppContext'

const AppProvider = ({ children }) => {
  let initialStoreState = {}

  const [store, updateStore] = useState(initialStoreState)

  return (
    <AppContext.Provider
      value={{
        store
      }}
    >
      {children}
    </AppContext.Provider>
  )
}
export default AppProvider
