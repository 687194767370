import React from 'react'

const initialState = {
  mobileMenu: false,
  toggleMobileMenu: () => { },
}

export const MenuContext = React.createContext(initialState)

class MenuState extends React.Component {
  constructor (props) {
    super(props)
    this.state = initialState
  }

  // componentDidMount () {
  //   document.addEventListener('keydown', this.escFunction, false)
  // }

  // componentWillUnmount () {
  //   document.removeEventListener('keydown', this.escFunction, false)
  // }

  // escFunction = e => {
  //   if (e && e.keyCode === 27) { // escape key maps to keycode `27`
  //     this.toggleCart()
  //   }
  // }

  toggleMobileMenu = () => {
    const { mobileMenu } = this.state
    if (!mobileMenu) this.setState({ mobileMenu: true })
    else this.setState({ mobileMenu: false })
  }

  render () {
    const { children } = this.props
    return (
      <MenuContext.Provider
        value={{
          ...this.state,
          toggleMobileMenu: this.toggleMobileMenu,
        }}
      >
        {children}
      </MenuContext.Provider>
    )
  }
}

export default MenuState