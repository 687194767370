export const margin = 100 / 14 + 'vw'
export const gutter = '3vw'

export const containerLargeMargins = '105px'
export const containerMediumMargins = '5vw'
export const containerSmallMargins = '20px'

const colSpacingSmall = '0'
const colSpacingMedium = '0'
const colSpacingLarge = '0'

const rowSpacingSmall = colSpacingSmall
const rowSpacingMedium = colSpacingMedium
const rowSpacingLarge = colSpacingLarge

const gridSettings = {
	colGap: [colSpacingSmall, colSpacingMedium, colSpacingLarge],
	rowGap: [rowSpacingSmall, rowSpacingMedium, rowSpacingLarge],
	vAlign: 'center'
}

export default gridSettings
