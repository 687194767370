import * as util from './util'

export const verticalMargins = `
	${ util.responsiveStyles('margin-top', 120, 90, 70, 40) }
	${ util.responsiveStyles('margin-bottom', 120, 90, 70, 40) }
`

export const verticalPadding = `
	${ util.responsiveStyles('padding-top', 120, 90, 70, 40) }
	${ util.responsiveStyles('padding-bottom', 120, 90, 70, 40) }
`
