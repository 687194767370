import React, { Fragment, useState, useCallback } from 'react'
import { StaticQuery, graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import globalStyles from 'src/styles/globalStyles'
import PageTransition from 'src/components/PageTransition'
import ScrollListener from 'src/components/ScrollListener'

import MenuListener from 'src/components/MenuListener'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import ScrollLock, { TouchScrollable } from 'react-scrolllock';

import ResponsiveComponent from 'src/components/ResponsiveComponent'
import MobileMenu from 'src/components/MobileMenu'
import MobileHeader from 'src/components/MobileHeader'
import { Global, css } from '@emotion/core'
import styled from '@emotion/styled'

import IntersectionObserverPolyfill from 'src/components/IntersectionObserverPolyfill'
import AppProvider from 'src/state/AppState'
import StaticHeader from 'src/components/StaticHeader'
import AniLink from "gatsby-plugin-transition-link/AniLink";
import './reset.css'
import { mediaQueries } from 'src/styles'

const FixedMobileWrap = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	// width: 100%;
	// height: 100%;
	z-index: 2;
	// background: plum;
	padding: 50px 0;
`


const Layout = ({ children, location }) => {
	const { allContentfulSiteSettings, nsf } = useStaticQuery(
    graphql`
      query {
        allContentfulSiteSettings {
			    edges {
			      node {
			        ...SiteSettings
			      }
			    }
			  }
			  nsf: file(relativePath:{eq: "images/nsf-logo.png"}) {
					publicURL
				}
      }
    `
  )
  const site = allContentfulSiteSettings.edges[0].node
  // console.log('allContentfulSiteSettings:::::', site)
  let loc = location.pathname.substring(1)
  // console.log('LAYOUT, LOCATOIN:::', loc)
  // let hoverShift = false;





	const [ mobileMenuOpen, toggleMobileMenu ] = useState(false)

 //  const handleMobileMenu = useCallback(
	//     (mobileMenuOpen) => {
	//     	console.log('handleMobileMenu')
	//       toggleMobileMenu(!mobileMenuOpen)
	//     	if ( mobileMenuOpen ) {
	// 	      disableBodyScroll()
	//     	} else {
	//     		enableBodyScroll();
	//     	}

 //    	},
 //    [mobileMenuOpen],
	// );
  
	// console.log('index, loc:::', loc.length)
	return (
		<Fragment>

			<StaticHeader
        navigation={site.navigation}
        active={loc}
        toggleMobileMenu={toggleMobileMenu}
        mobileMenuOpen={mobileMenuOpen}
        // handleHoverState={this.handleHoverState}
			/>
	{/*
		<ResponsiveComponent
        small={
        	<Fragment>
        		<MobileHeader
        			mobileMenuOpen={mobileMenuOpen}
	            toggleMobileMenu={toggleMobileMenu}
        		/>
	          <MobileMenu
	            open={mobileMenuOpen}
	            toggleMobileMenu={toggleMobileMenu}
	            navLinks={site.navigation}
	          // footerColumn1={footerColumn1}
	          // footerColumn2={footerColumn2}
	          />
          </Fragment>
        }
        medium={
        	<StaticHeader
		        navigation={site.navigation}
		        active={loc}
		        toggleMobileMenu={toggleMobileMenu}
		        mobileMenuOpen={mobileMenuOpen}
		        // handleHoverState={this.handleHoverState}
					/>
        }
      />
	*/}		
			<IntersectionObserverPolyfill>
				<AppProvider>
	        <ScrollListener>
						<Global
							styles={css`${ globalStyles }`}
						/>
							{children}
					</ScrollListener>
				</AppProvider>
			</IntersectionObserverPolyfill>

			<ScrollLock isActive={mobileMenuOpen} />

			

		</Fragment>
	)
}

Layout.propTypes = {
	children: PropTypes.node.isRequired
}

export default Layout
